import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";
import BotaoSimular from "@/components/BotaoSimular";
import "./liberte-se-da-inadimplencia-e-conquiste-a-libertade-financeira.scss";

export default function LiberdadeDaInadimplencia() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_LIBERTE-SE_DA_INADIMPLENCIA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-site " style={{ background: "white" }}>
        <Grid container spacing={2}>
          <Grid
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid container maxWidth="sm">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  style={{ maxHeight: "490px" }}
                  src="/site/blog/blog-liberte-se-da-inadimplencia.png"
                  alt="logo-blog"
                />
              </Grid>
              <Grid sx={{ pt: 4 }}>
                <Typography variant="h1" sx={{ mt: 2 }}>
                  Liberte-se da inadimplência e conquiste sua liberdade
                  financeira
                </Typography>
              </Grid>

              <Grid container className="sessao intro">
                <Typography className="descricao" component={"article"}>
                  Neste artigo, vamos explorar estratégias práticas para superar
                  a inadimplência e alcançar a liberdade financeira. Com a ajuda
                  da <span className="quita">Quita Boletos</span>, você pode dar
                  os primeiros passos rumo a uma vida financeira mais tranquila
                  e estável.
                </Typography>
                <Typography
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Vamos descobrir como é possível transformar dívidas em
                  oportunidades de crescimento financeiro!
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Como alcançar a liberdade financeira: dicas práticas da{" "}
                    <span className="quita">Quita Boletos</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography className="descricao" component={"article"}>
                    Viver endividado pode ser uma fonte constante de estresse e
                    ansiedade.
                  </Typography>
                  <Typography
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    No entanto, com o parcelamento flexível oferecido pela{" "}
                    <span className="quita">Quita Boletos</span>, é possível dar
                    um novo rumo para suas finanças. Nossa solução de
                    parcelamento permite que você divida seus boletos em até 12
                    vezes, proporcionando alívio imediato para o seu orçamento.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="descricao" component={"article"}>
                    A conveniência e rapidez do processo são aspectos essenciais
                    para quem busca soluções eficazes para a inadimplência.
                  </Typography>
                  <Typography
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Com a <span className="quita">Quita Boletos</span>, você
                    pode resolver suas pendências financeiras de forma
                    descomplicada, sem burocracia e sem estresse. Nosso
                    compromisso é oferecer uma experiência incrível, garantindo
                    que você possa retomar o controle de suas finanças com
                    facilidade.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="descricao" component={"article"}>
                    Além disso, <b>nossa nota de 9,2 no Reclame Aqui</b> é uma
                    prova real de nossa dedicação ao atendimento ao cliente e à
                    satisfação dos nossos clientes.
                  </Typography>
                  <Typography
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    <b>Mais de 35 mil pessoas</b> já se beneficiaram do nosso
                    serviço e conquistaram sua liberdade financeira. Agora, é a
                    sua vez!
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Chegou o momento de deixar a inadimplência para trás e dar
                    as boas-vindas a uma vida financeira mais estável e
                    tranquila. Com a{" "}
                    <span className="quita">Quita Boletos</span>, você pode
                    transformar suas dívidas em oportunidades de crescimento
                    financeiro. Dê o primeiro passo em direção à sua liberdade
                    financeira hoje mesmo!
                  </Typography>
                </Grid>

                <Grid item xs={12} align="center">
                  <BotaoSimular>
                    CLIQUE AQUI E REALIZE UMA SIMULAÇÃO
                  </BotaoSimular>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape />
    </>
  );
}
