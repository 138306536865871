import SiteLayout from "../layout/SiteLayout";
import Blog from "../pages/site/Blog";
import BeneficiosDoParcelamento from "../pages/site/Blog/Posts/benefícios-do-parcelamento-de-contas";
import EndividamentoImpactaPsicologico from "../pages/site/Blog/Posts/endividamento-impacto-psicologico";
import ParcelarMeusBoletos from "../pages/site/Blog/Posts/parcelamento-boletos";
import FgtsAdiantamento from "../pages/site/Blog/Posts/fgts-fazer-adiantamento";
import SegredoBoletos from "../pages/site/Blog/Posts/segredo-sobre-parcelar-boletos";
import CincoBeneficiosParcelerBoletos from "../pages/site/Blog/Posts/5-beneficios-de-parcelar-boletos";
import MenosDividasMaisEsperancaFinanceira from "../pages/site/Blog/Posts/brasil-2023-menos-dividas-mais-esperanca-financeira";
import LiberdadeDaInadimplencia from "../pages/site/Blog/Posts/liberte-se-da-inadimplencia-e-conquiste-a-libertade-financeira";
import MasAfinalComoFunciona from "../pages/site/Blog/Posts/mas-afinal-como-funciona";
import GuiaParaManterAsFinancasEmOrdem from "../pages/site/Blog/Posts/guia-para-manter-as-financas-em-ordem";
import ParcelamentoDeContasEstrategiaInteligente from "../pages/site/Blog/Posts/parcelamento-de-contas";
import TudoQuePrecisaSaberSobreParcelamentoPix from "../pages/site/Blog/Posts/parcelamento-pix";
import ParcelarContasAtravesDoCartaoDeCredito from "../pages/site/Blog/Posts/parcelar-contas-atraves-cartao-de-credito";
import ComoPagarBoletoVencido from "../pages/site/Blog/Posts/como-pagar-boleto-vencido";
import EntendaComoParcelarIPTUeIPVANoCartao from "../pages/site/Blog/Posts/entenda-como-parcelar-iptu-e-ipva-no-cartao";
import ParcelamentoDePixNoCredito from "../pages/site/Blog/Posts/parcelamento-de-pix-no-credito";
import ParcelamentoDeContasTudoQuePrecisaSaber from "../pages/site/Blog/Posts/parcelamento-de-contas-tudo-que-precisa-saber";
import DicasParaManterAsContasEmDia from "../pages/site/Blog/Posts/5-dicas-para-manter-as-contas-em-dia";
import HabitosDePessoasBemSucedidas from "../pages/site/Blog/Posts/5-habitos-de-pessoas-bem-sucedidas";
import ComoManterSeusPagamentosEmDia from "../pages/site/Blog/Posts/organizacao-financeira-como-manter-seus-pagamentos-em-dia";

const BlogRoutes = {
  path: "/blog",
  element: <SiteLayout />,
  children: [
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "como-parcelar-meus-boletos",
      element: <ParcelarMeusBoletos />,
    },
    {
      path: "endividamento-impacta-psicologico",
      element: <EndividamentoImpactaPsicologico />,
    },
    {
      path: "os-beneficios-do-parcelamento-de-contas",
      element: <BeneficiosDoParcelamento />,
    },
    {
      path: "fgts-fazer-adiantamento-ou-nao",
      element: <FgtsAdiantamento />,
    },
    {
      path: "segredos-que-ninguem-te-contou",
      element: <SegredoBoletos />,
    },
    {
      path: "cinco-beneficios-de-parcelar-boletos",
      element: <CincoBeneficiosParcelerBoletos />,
    },
    {
      path: "menos-dividas-mais-esperanca",
      element: <MenosDividasMaisEsperancaFinanceira />,
    },
    {
      path: "liberte-se-da-inadimplencia",
      element: <LiberdadeDaInadimplencia />,
    },
    {
      path: "como-funciona",
      element: <MasAfinalComoFunciona />,
    },
    {
      path: "guia-para-manter-as-financas-em-ordem",
      element: <GuiaParaManterAsFinancasEmOrdem />,
    },
    {
      path: "parcelamento-de-contas",
      element: <ParcelamentoDeContasEstrategiaInteligente />,
    },
    {
      path: "parcelamento-de-pix",
      element: <TudoQuePrecisaSaberSobreParcelamentoPix />,
    },
    {
      path: "parcelar-atraves-do-cartao-de-credito",
      element: <ParcelarContasAtravesDoCartaoDeCredito />,
    },
    {
      path: "como-pagar-boleto-vencido",
      element: <ComoPagarBoletoVencido />,
    },
    {
      path: "parcelar-iptu-e-ipva-no-cartao",
      element: <EntendaComoParcelarIPTUeIPVANoCartao />,
    },
    {
      path: "parcelamento-pix-credito",
      element: <ParcelamentoDePixNoCredito />,
    },
    {
      path: "tudo-que-precisa-saber-sobre-parcelamento-de-contas",
      element: <ParcelamentoDeContasTudoQuePrecisaSaber />,
    },
    {
      path: "dicas-para-manter-as-contas-em-dia",
      element: <DicasParaManterAsContasEmDia />,
    },
    {
      path: "habitos-de-pessoas-bem-sucedidas",
      element: <HabitosDePessoasBemSucedidas />,
    },
    {
      path: "como-manter-seus-pagamentos-em-dia",
      element: <ComoManterSeusPagamentosEmDia />,
    },
  ],
};

export default BlogRoutes;
