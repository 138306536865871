import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Rodape from "../rodape";
import CardPosts from "./CardPosts";
import "./index.scss";

export default function Blog() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Helmet>
        <title>Blog</title>
        <link rel="canonical" href="https://quitaboletos.com.br/blog" />
        <meta
          name="description"
          content="Confira nossas postagens e receba dicas incríveis para organizar seu orçamento."
        />
      </Helmet>
      <Container className="sessao-site pagina-blog">
        <Grid container spacing={3} sx={{ p: 3 }} justifyContent="center">
          <Grid xs={12} align="center">
            <Typography className="titulo" variant="h3" sx={{ p: 3, mt: 3 }}>
              Confira nosso <span className="quita">Blog</span>
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: "grid",
              justifyItems: "center",
            }}>
            <CardPosts
              sx={{
                margin: "1rem",
                marginBottom: "2rem",
                width: isMobile ? "auto" : 600,
              }}
              {...{
                justThree: false,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Rodape></Rodape>
    </>
  );
}
