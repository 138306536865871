import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";
import BotaoSimular from "@/components/BotaoSimular";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";

export default function ParcelamentoDeContasEstrategiaInteligente() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_PARCELAMENTO_DE_CONTAS_UMA_ESTRATEGIA_INTELIGENTE",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-site " style={{ background: "white" }}>
        <Grid container justifyContent="center">
          <Grid
            xs={12}
            sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={3} maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  style={{ maxHeight: "400px" }}
                  src="/site/blog/blog_parcelamento_de_contas.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Parcelamento de Contas: Uma estratégia inteligente para
                  reorganizar suas finanças
                </Typography>
              </Grid>

              <Grid container spacing={1} className="sessao">
                <Grid item xs={12}>
                  <Typography variant="h3" className="titulo">
                    Em meio a um cenário de incertezas financeiras, encontrar
                    maneiras de equilibrar o orçamento e manter as contas em dia
                    pode ser um verdadeiro desafio. Felizmente, o parcelamento
                    de contas surge como uma solução eficaz para quem precisa
                    reorganizar suas finanças de forma inteligente e acessível.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Neste artigo, separamos algumas dicas que vão ajudá-lo a
                    compreender melhor as vantagens do parcelamento de contas.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={1} className="sessao tutorial">
                <Grid item xs={12}>
                  <ol className="lista">
                    <li>
                      <b>Flexibilidade Financeira:</b> O parcelamento de contas
                      oferece flexibilidade financeira, permitindo que você
                      dívida o pagamento de despesas em parcelas menores ao
                      longo do tempo. Isso ajuda a aliviar a pressão financeira
                      imediata e permite que você ajuste seus gastos de acordo
                      com sua capacidade financeira.
                    </li>
                    <li>
                      <b>Manutenção do Orçamento:</b> Ao parcelar contas, você
                      pode manter seu orçamento sob controle, evitando gastos
                      excessivos ou comprometimento de uma grande quantia de uma
                      só vez. Isso permite que você distribua os custos ao longo
                      do tempo, tornando-os mais gerenciáveis e previsíveis.
                    </li>
                    <li>
                      <b>Evita Juros Abusivos:</b> Em muitos casos, o
                      parcelamento de contas pode ser livre de juros ou
                      apresentar taxas de juros mais baixas do que outras formas
                      de crédito disponíveis no mercado. Isso significa que você
                      economiza dinheiro ao evitar o pagamento de juros abusivos
                      sobre o valor total da dívida.
                    </li>
                    <li>
                      <b>Mantém a Saúde Financeira:</b> Ao optar pelo
                      parcelamento de contas, você pode manter sua saúde
                      financeira, evitando dívidas acumuladas e preservando sua
                      capacidade de crédito.{" "}
                      <i>
                        Isso é especialmente importante para garantir
                        estabilidade financeira a longo prazo e evitar situações
                        de inadimplência
                      </i>
                    </li>
                    <li>
                      <b>
                        Parcelamento com a{" "}
                        <span className="quita">Quita Boletos</span>:
                      </b>{" "}
                      Com a <span className="quita">Quita Boletos</span>, você
                      tem a opção de parcelar <b>PIX</b> ou <b>boletos</b> em
                      até <b>12 vezes no cartão de crédito</b>. Essa facilidade
                      permite que você gerencie suas despesas de forma
                      conveniente, sem comprometer seu orçamento mensal e sem a
                      necessidade de recorrer a empréstimos ou financiamentos
                      com juros elevados.
                    </li>
                  </ol>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    O parcelamento de contas é uma estratégia inteligente e
                    acessível para quem busca reorganizar suas finanças e manter
                    o equilíbrio orçamentário. Ao oferecer flexibilidade
                    financeira, manutenção do orçamento, evitar juros abusivos e
                    preservar a saúde financeira, essa modalidade de pagamento
                    se mostra como uma solução viável para enfrentar desafios
                    financeiros e conquistar uma vida mais estável e tranquila.
                  </Typography>
                </Grid>

                <Grid item xs={12} align="center">
                  <BotaoSimular>
                    CLIQUE AQUI E REALIZE UMA SIMULAÇÃO
                  </BotaoSimular>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
}
