import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";
import BotaoSimular from "@/components/BotaoSimular";
import "./index.scss";

export default function ComoPagarBoletoVencido() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_COMO_PAGAR_BOLETO_VENCIDO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-site" style={{ background: "white" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                style={{ maxHeight: "400px" }}
                src="/site/blog/blog-como-pagar-boleto-vencido.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Como pagar boleto vencido?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">
                Quem nunca se viu em apuros ao perceber que esqueceu de pagar um
                boleto até a data de vencimento?
              </Typography>
              <Typography variant="h4" sx={{ mt: 2 }}>
                A correria do dia a dia muitas vezes pode nos fazer deixar
                passar despercebidos alguns compromissos financeiros. Mas,
                calma! Com a <span className="quita">Quita Boletos</span>, pagar
                boletos vencidos nunca foi tão fácil e prático.
              </Typography>
            </Grid>

            <Grid item container spacing={2} className="sessao">
              <Grid item xs={12}>
                <Typography variant="h3">
                  Como funciona a <span className="quita">Quita Boletos</span>?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20, pb: 1 }}>
                  A <span className="quita">Quita Boletos</span> é uma
                  plataforma online que permite o parcelamento de <b>boletos</b>{" "}
                  ou <b>PIX</b>, em até 12x no cartão de crédito. Com ela, você
                  pode regularizar seus débitos de forma rápida, segura e sem
                  burocracia. E ainda, tem a flexibilidade de escolher em
                  quantas vezes deseja parcelar o seu débito.
                </Typography>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  O funcionamento da{" "}
                  <span className="quita">Quita Boletos</span> é simples e
                  descomplicado. Basta acessar o nosso site, informar os seus
                  dados e os dados do boleto que deseja pagar, escolher o
                  parcelamento ideal e informar os dados do seu cartão de
                  crédito.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Quais as vantagens de utilizar a{" "}
                  <span className="quita">Quita Boletos</span>?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Além da praticidade e flexibilidade, a{" "}
                  <span className="quita">Quita Boletos</span> oferece uma série
                  de vantagens exclusivas para os seus usuários. Confira algumas
                  delas:
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ fontSize: 20 }}>
                <ol>
                  <li>
                    <b>Economia de tempo:</b> Chega de perder horas em filas de
                    banco ou em ligações intermináveis para o atendimento ao
                    cliente. Com a <span>Quita Boletos</span>, você resolve tudo
                    de forma rápida e sem sair de casa.
                  </li>
                  <li>
                    <b>Redução de juros e multas:</b> Ao regularizar seus
                    boletos vencidos com a Quita Boletos, você evita o acúmulo
                    de juros e multas, mantendo as suas finanças em dia e
                    evitando dores de cabeça no futuro.
                  </li>
                  <li>
                    <b>Segurança:</b> Todas as transações realizadas através da{" "}
                    <span className="quita">Quita Boletos</span> são totalmente
                    seguras, garantindo a proteção dos seus dados pessoais e
                    financeiros.
                  </li>
                </ol>
              </Grid>

              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Se você está enfrentando problemas com boletos vencidos, conte
                  com a <span className="quita">Quita Boletos</span> para
                  regularizar os seus débitos de forma rápida, segura e sem
                  complicações.
                </Typography>
                <Typography component={"article"} sx={{ fontSize: 20 }}>
                  Acesse agora mesmo o nosso site e descubra como é fácil
                  simplificar a sua vida financeira!
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular></BotaoSimular>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
}
