import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import "./index.scss";

function Rodape() {
  const navigation = useNavigate();
  useEffect(() => {
    const reclameAquiDiv = document.getElementById("ra-verified-seal");
    const script = document.createElement("script");

    script.setAttribute(
      "src",
      "https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js"
    );
    reclameAquiDiv.appendChild(script);
    reclameAquiDiv.removeChild(script);
  }, []);

  return (
    <Container className="sessao-site sessao-rodape">
      <Grid container sx={{ p: 3 }} justifyContent="center">
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" },
          }}>
          <Typography className="imagem">
            <img src="/site/logo_rodape.png" alt=""></img>
          </Typography>
          <Typography className="contato">(55) 99904 2233</Typography>
          <Typography className="contato">
            contato@quitaboletos.com.br
          </Typography>

          <div id="ra-verified-seal" className="logo-reclame-aqui">
            <script
              type="text/javascript"
              style={{ backgroundColor: "red" }}
              id="ra-embed-verified-seal"
              src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js"
              data-id="RmNnY2lQZHFSLVo0b0RNcTpxdWl0YS1ib2xldG9z"
              data-target="ra-verified-seal"
              data-model="2"></script>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            textAlign: { xs: "center", sm: "right" },
          }}>
          <Typography className="redes-sociais">
            <InstagramIcon
              titleAccess="Instagram"
              onClick={() => {
                window.open(
                  "https://www.instagram.com/quitaboletos/",
                  "_blank"
                );
              }}></InstagramIcon>
            <FacebookIcon
              titleAccess="Facebook"
              onClick={() => {
                window.open("https://www.facebook.com/QuitaBoletos/", "_blank");
              }}></FacebookIcon>
            <IconButton
              title="TikTok"
              sx={{}}
              onClick={() => {
                window.open(
                  "https://www.tiktok.com/@quitaboletosoficial",
                  "_blank"
                );
              }}>
              <img src="/site/tiktok.png" width="40px" height="40px" alt="" />
            </IconButton>
            <IconButton
              title="X"
              sx={{}}
              onClick={() => {
                window.open("https://x.com/quitaboletos", "_blank");
              }}>
              <img src="/site/x.png" alt="" />
            </IconButton>
            <YouTubeIcon
              titleAccess="YouTube"
              onClick={() => {
                window.open(
                  "https://www.youtube.com/channel/UCMDBfomX_bSscVXL9Waj4dw",
                  "_blank"
                );
              }}></YouTubeIcon>
            <LinkedInIcon
              titleAccess="Linkedin"
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/quita-boletos/",
                  "_blank"
                );
              }}></LinkedInIcon>
          </Typography>
          <Typography className="endereco">CNPJ: 42.426.994/0001-52</Typography>
          <Typography className="endereco">Quita Tecnologia LTDA</Typography>
          <Typography className="endereco">Rua Santa Rosa, 167</Typography>
          <Typography className="endereco">
            Horizontina RS | Brasil CEP 98920-000
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" sx={{ mb: 2, p: 1 }}>
        <Grid item xs={12} align="center">
          <Typography>
            Em acordo as diretrizes do BACEN, nos termos da Resolução n° 3.954
            de 24 de fevereiro de 2011.
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography>Somos correspondentes bancários autorizados.</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <Typography>
            VIACERTA FINANCIADORA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO,
            instituição financeira inscrita no CNPJ 05.192.316/0001-46.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          className="termos-uso"
          justifyContent="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 2,
          }}>
          <Typography
            onClick={() => {
              navigation("/termos-de-uso");
            }}>
            Termos de uso
          </Typography>
          <Typography className="barra">|</Typography>
          <Typography
            onClick={() => {
              window.open("/politica-privacidade.html", "_blank");
            }}>
            Política de privacidade
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Rodape;
