import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";

export default function FgtsAdiantamento() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_FGTS_FAZER_OU_NAO_O_ADIANTAMENTO",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container
        className="sessao-site "
        justifyContent="center"
        style={{ background: "white" }}>
        <Grid container justifyContent="center">
          <Grid
            className="logo-blog"
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="sm"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  style={{ maxHeight: "400px" }}
                  src="/site/blog-fgts.png"
                  alt="logo-blog"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  FGTS: fazer ou não o adiantamento?
                </Typography>
              </Grid>

              <Grid container className="sessao intro">
                <Typography
                  sx={{ fontSize: 20 }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Com a liberação do FGTS, surge uma oportunidade única de ter
                  um dinheiro extra, mas que deve ser usado e administrado com
                  consciência e inteligência financeira. Além de levantar o
                  questionamento de ser ou não uma boa opção para realizar o
                  adiantamento do FGTS.
                </Typography>
                <Typography
                  sx={{ fontSize: 20, width: "100%" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Por isso, preparamos uma leitura de{" "}
                  <span className="greenUnderline">5 minutos</span> para lhe
                  ajudar.
                </Typography>
              </Grid>

              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Veja o seu FGTS como uma reserva financeira
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    O Fundo de Garantia do Tempo de Serviço (FGTS) é uma
                    importante reserva financeira para os trabalhadores
                    brasileiros, que pode ser uma ótima oportunidade para
                    realizar desejos e melhorar a vida financeira. Ainda assim,
                    nem tudo são flores, existem os pontos fortes e fracos, de
                    receber esse adiantamento referente ao fundo.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Nesse sentido, resolvemos te mostrar as vantagens e
                    desvantagens que acompanham a liberação do FGTS.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Vantagens da liberação do FGTS
                  </Typography>
                </Grid>
                <ul className="lista">
                  <li>
                    <span className="quita">
                      Realização de Sonhos e Projetos:
                    </span>
                    A liberação do saldo do FGTS possibilita que os
                    trabalhadores realizem sonhos há muito tempo adiados. Essa
                    injeção de recursos pode tornar possíveis projetos que antes
                    pareciam distantes.
                  </li>
                  <li>
                    <span className="quita">Quitação de Dívidas: </span>
                    Para quem possui dívidas em atraso, o FGTS liberado pode ser
                    a oportunidade de colocar as contas em dia e livrar-se dos
                    juros e multas acumulados. A quitação de dívidas proporciona
                    alívio financeiro e melhora a saúde psicológica do
                    trabalhador.
                  </li>
                  <li>
                    <span className="quita">Impulso na Economia: </span>
                    Esta liberação, adiantamento, como queiram chamar, pode
                    gerar um impacto positivo na economia, pois os trabalhadores
                    tendem a gastar parte desses recursos em consumo, o que
                    estimula o comércio e diversos setores da economia.
                  </li>
                  <li>
                    <span className="quita">
                      Estímulo ao Mercado Imobiliário:{" "}
                    </span>
                    Para quem sonha em comprar a casa própria, o saldo do FGTS
                    pode ser utilizado para dar entrada em um imóvel, reduzindo
                    o valor financiado e as parcelas mensais.
                  </li>
                </ul>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Pontos negativos de receber o FGTS
                  </Typography>
                </Grid>
                <ul className="lista">
                  <li>
                    <span className="quita">
                      Perda do Fundo de Garantia em Caso de Demissão Sem Justa
                      Causa:{" "}
                    </span>
                    Ao realizar o saque do FGTS, o trabalhador perde a garantia
                    de receber o fundo em caso de demissão sem justa causa. Isso
                    pode ser um risco para quem não possui estabilidade no
                    emprego ou está enfrentando um cenário de incertezas no
                    mercado de trabalho.
                  </li>
                  <li>
                    <span className="quita">
                      Rendimento do fundo de garantia:{" "}
                    </span>
                    Outro ponto a ser considerado é o rendimento do FGTS. O
                    saldo do fundo é corrigido anualmente com base em uma taxa
                    determinada pelo governo, e, ao sacar o valor, o trabalhador
                    deixa de contar com esse rendimento, que pode contribuir
                    para a valorização do dinheiro ao longo do tempo.
                  </li>
                  <li>
                    <span className="quita">Impacto na aposentadoria: </span>O
                    FGTS também pode ser uma importante reserva financeira para
                    o futuro, especialmente na aposentadoria. Ao optar pelo
                    saque, o trabalhador pode comprometer a construção dessa
                    reserva, o que pode afetar a segurança financeira quando se
                    aposentar.
                  </li>
                </ul>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Indeciso sobre o Próximo Passo?
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Para tomar uma decisão informada, é fundamental compreender
                    a sua situação, avaliar suas finanças e determinar se faz
                    sentido solicitar o adiantamento do saque do FGTS.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Analise o impacto que esse montante teria em caso de
                    desemprego, ou se você já possui outra reserva de
                    emergência, caso seja necessário. Ao ponderar cuidadosamente
                    esses fatores, você estará mais preparado para tomar uma
                    decisão que atenda às suas necessidades financeiras de
                    maneira responsável.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape />
    </>
  );
}
