import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";
import BotaoSimular from "@/components/BotaoSimular";

export default function SegredoBoletos() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_UM_SEGREDO_QUE_POUCOS_CONTAM_SOBRE_PAGAR_BOLETOS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container
        className="sessao-site "
        justifyContent="center"
        style={{ background: "white" }}>
        <Grid container justifyContent="center">
          <Grid
            className="logo-blog"
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="sm"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  style={{ maxHeight: "400px" }}
                  src="/site/blog-segredo-boletos.png"
                  alt="logo-blog"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Um segredo que poucos te contam sobre pagar boletos com cartão
                  de crédito.
                </Typography>
              </Grid>
              <Grid container className="sessao intro">
                <Typography
                  sx={{ fontSize: 20, fontStyle: "italic", fontWeight: "bold" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  <span className="greenUnderline">
                    Já faz algum tempo que o pagamento de contas através de
                    cartão de crédito chegou para a felicidade geral da nação.
                  </span>
                </Typography>
                <Typography
                  sx={{ fontSize: 20 }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Pagar os benditos boletos é uma tarefa que faz parte do nosso
                  cotidiano, mas, aí vem algo que talvez você ainda não saiba.
                  Presta atenção aqui ó: É possível transformar essa atividade
                  em uma oportunidade para acumular milhas e vantagens!!!!
                </Typography>
                <Typography
                  sx={{ fontSize: 20, width: "100%" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Neste artigo, vamos explorar como essa estratégia inteligente
                  pode trazer benefícios reais para sua vida financeira.
                  Acompanhe e descubra como fazer suas contas trabalharem a seu
                  favor!
                </Typography>
              </Grid>

              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Passo a passo para acumular milhas
                  </Typography>
                </Grid>
                <ol className="lista">
                  <li>
                    <span className="quita">Escolha o boleto ou conta: </span>
                    Comece selecionando uma conta ou boleto que você normalmente
                    pagaria via boleto ou transferência bancária.
                  </li>
                  <li>
                    <span className="quita">Use o cartão de crédito: </span>
                    Opte por pagar essa conta usando o cartão de crédito,
                    garantindo que ele tenha a função de acumular milhas (isso
                    deve ser verificado diretamente com a empresa do cartão).
                    Porém, se você está dividido entre algumas prioridades,
                    existe a possibilidade de usar este um e mais cartões no
                    parcelamento de boletos da{" "}
                    <span className="quita">Quita</span>. Com certeza, vai ser
                    uma vantagem atrás da outra.
                  </li>
                  <li>
                    <span className="quita">Acompanhe suas milhas: </span>
                    As milhas acumuladas vão para o seu programa de recompensas.
                    Certifique-se de acompanhar o saldo regularmente.
                  </li>
                </ol>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Vantagens de acumular milhas
                  </Typography>
                </Grid>
                <ul className="lista">
                  <li>
                    <span className="quita">Viagens: </span>
                    Você pode obter viagens gratuitas ou com desconto
                  </li>
                  <li>
                    <span className="quita">Produtos e serviços: </span>
                    Algumas companhias de cartão de crédito permitem que você
                    troque suas milhas por produtos, serviços ou descontos em
                    estabelecimentos parceiros.
                  </li>
                  <li>
                    <span className="quita">Upgrade de status: </span>
                    Dependendo do programa de milhas, você pode alcançar status
                    de cliente preferencial, recebendo benefícios como embarque
                    prioritário e acesso a salas VIP.
                  </li>
                </ul>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    A importância da escolha certa
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Nem todos os cartões de crédito acumulam milhas da mesma
                    forma. É fundamental escolher um cartão que se alinhe ao seu
                    estilo de vida e objetivos financeiros.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    No cenário das fintechs no Brasil, a oferta de cartões de
                    crédito que proporcionam o acúmulo de milhas e cashback tem
                    ganhado grande destaque, transformando a forma como as
                    pessoas gerenciam suas finanças e aproveitam vantagens.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    No entanto, uma das maiores revoluções trazidas pelas
                    fintechs é a personalização desses benefícios.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Hoje, cartões de crédito podem ser escolhidos de acordo com
                    o perfil e necessidades do usuário. Por exemplo, se você
                    viaja frequentemente, um cartão que bonifica gastos
                    relacionados a viagens, como passagens aéreas e hospedagem,
                    pode ser a escolha certa para maximizar suas milhas.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography variant="h1" className="titulo">
                    Pix: A facilidade do pagamento instantâneo
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Além do cartão de crédito, o Pix é outra ferramenta que pode
                    otimizar o pagamento de contas e boletos. Com sua velocidade
                    e conveniência, o Pix torna o processo ainda mais prático,
                    permitindo que você aproveite vantagens e acumule milhas de
                    maneira eficiente.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Agora definitivamente, você deve estar pensando: “Mas e daí?
                    O assunto do artigo não é o pagamento com cartão de crédito
                    para transformar em milhas? “, bem, assim como o
                    parcelamento de boletos com a utilização dos seus cartões, o
                    PIX é uma das nossas ferramentas maravilhosas de quitação de
                    dívidas, e adivinha? Você também pode parcelar o PIX
                    utilizando seu cartão de crédito.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Assim sendo, se você optar por PIX, você também ganha! Com a
                    <span className="quita"> Quita boletos</span>, você sempre
                    ganha.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Pagar contas e boletos com o cartão de crédito não é apenas
                    uma conveniência, é uma estratégia inteligente que pode
                    melhorar significativamente sua vida financeira. Ao usar o
                    poder do cartão e a praticidade do Pix, você pode
                    transformar seus pagamentos em oportunidades.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Comece hoje mesmo e colha os benefícios no futuro! Entre em
                    contato com a nossa equipe ou acesse diretamente o nosso
                    site e faça sua simulação, sem complicações e a um clique de
                    distância.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="simulacao">
            <BotaoSimular>CLIQUE AQUI E REALIZE UMA SIMULAÇÃO</BotaoSimular>
          </Grid>
        </Grid>
      </Container>

      <Rodape />
    </>
  );
}
