import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";
import BotaoSimular from "@/components/BotaoSimular";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";

export default function CincoBeneficiosParcelerBoletos() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_5_BENEFICIOS_PARCELAR_SEUS_BOLETOS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container
        className="sessao-site "
        justifyContent="center"
        style={{ background: "white" }}>
        <Grid container justifyContent="center">
          <Grid
            className="logo-blog"
            xs={12}
            sx={{ p: 2, display: { sm: "flex" }, justifyContent: "center" }}>
            <Grid
              container
              spacing={3}
              maxWidth="sm"
              sx={{ pt: 3, pb: 3 }}
              justifyContent="center">
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  style={{ maxHeight: "400px" }}
                  src="/site/blog-5-beneficios.png"
                  alt="logo-blog"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  5 Benefícios de parcelar seus boletos
                </Typography>
              </Grid>

              <Grid container className="sessao intro">
                <Typography
                  sx={{ fontSize: 20, fontStyle: "italic", fontWeight: "bold" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  <span className="greenUnderline">
                    Fazer compras e pagar contas é uma parte inevitável da vida
                    adulta.
                  </span>
                </Typography>
                <Typography
                  sx={{ fontSize: 20, width: "100%" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  No entanto, a forma como escolhemos pagar por esses
                  compromissos pode fazer uma grande diferença em nossa saúde
                  financeira. A princípio, uma das opções cada vez mais
                  populares é o parcelamento de boletos, que oferece uma série
                  de benefícios.
                </Typography>
                <Typography
                  sx={{ fontSize: 20, width: "100%" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Além disso, existem inúmeras fintechs que propiciam vantagens
                  como essa, que facilitam a vida e permitem mil possibilidades
                  na hora de pagar contas.
                </Typography>
                <Typography
                  sx={{ fontSize: 20, width: "100%" }}
                  className="descricao"
                  component={"article"}
                  gutterBottom>
                  Neste artigo, exploraremos sete benefícios do boleto
                  parcelado, incluindo como a{" "}
                  <span className="quita">Quita Boletos</span> e o Pix Parcelado
                  podem ser uma solução inteligente para suas finanças. Sendo
                  assim, saiba que esta leitura requer apenas 5 minutos de sua
                  atenção, então aprecie cada palavra.
                </Typography>
              </Grid>

              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    1. Flexibilidade financeira
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    O boleto parcelado oferece uma flexibilidade financeira que
                    os pagamentos à vista não proporcionam. Ele permite que você
                    divida uma despesa em várias parcelas que cabem no seu
                    bolso.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    O que torna mais fácil ajustar seu orçamento mensal, e assim
                    flexibiliza as possibilidades tanto de adquirir novos bens
                    como realizar sonhos.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    2. Controle financeiro
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Com parcelas fixas, você tem um melhor controle sobre suas
                    finanças.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Desde já, você sabe exatamente quanto pagará todos os meses,
                    pois você escolhe a quantidade de parcelamento mais de
                    enquadra nos suas possibilidades de pagamento, o que
                    facilita o planejamento financeiro a longo prazo.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    3. Aumento da elegibilidade de compra
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Um dos benefícios mais notáveis do boleto parcelado,
                    especialmente quando combinado com a{" "}
                    <span className="quita">Quita</span>, é o aumento
                    significativo da elegibilidade de compra que oferece aos
                    consumidores. Essa vantagem tem o potencial de abrir portas
                    para uma série de oportunidades financeiras e de consumo.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    O boleto parcelado oferece aos consumidores uma alternativa
                    às opções de pagamento tradicionais, como o pagamento à
                    vista.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Muitos consumidores enfrentam barreiras financeiras ao lidar
                    com despesas imprevistas ou compras de alto valor. O boleto
                    parcelado, quando utilizado com sabedoria, pode ajudar a
                    eliminar essas barreiras, permitindo que as pessoas adquiram
                    o que precisam sem se sobrecarregar financeiramente. Ao
                    eliminar a necessidade de pagamento integral no momento da
                    compra, o boleto parcelado torna as compras mais acessíveis
                    e menos restritivas.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    4. Vantagens da <span className="quita">Quita</span>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    Através dos nossos serviços você obtém ainda mais benefícios
                    ao parcelar seus boletos.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Oferecemos taxas competitivas, um processo simples e seguro
                    para ajudar você a quitar suas contas sem estresse.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className="sessao beneficios">
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className="titulo"
                    sx={{ textAlign: "left !important" }}>
                    5. Pix parcelado
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 20 }}
                    className="descricao"
                    gutterBottom
                    component={"article"}>
                    A <span className="quita">Quita Boletos </span>vai além ao
                    oferecer a opção de Pix parcelado. Agora você pode pagar
                    seus boletos com a velocidade e conveniência do Pix,
                    tornando o processo de pagamento ainda mais simples.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Não importa se você está lidando com despesas inesperadas ou
                    planejando grandes compras, o parcelamento de boletos pode
                    ser a solução que você precisa para manter suas finanças sob
                    controle. Experimente a
                    <span className="quita"> Quita Boletos</span> e veja como
                    podemos simplificar o pagamento de seus boletos hoje mesmo.
                  </Typography>
                  <Typography
                    sx={{ fontSize: 20 }}
                    gutterBottom
                    className="descricao"
                    component={"article"}>
                    Sua vida financeira nunca foi tão fácil e conveniente! Entre
                    em contato com nossa equipe hoje mesmo ou simule direto no
                    site.
                  </Typography>
                  <Grid item xs={12} align="center">
                    <BotaoSimular>
                      CLIQUE AQUI E REALIZE UMA SIMULAÇÃO
                    </BotaoSimular>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape />
    </>
  );
}
