import { Container, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import Rodape from "@/pages/site/rodape";
import "./index.scss";

export default function HabitosDePessoasBemSucedidas() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_5_HABITOS_DE_PESSOAS_BEM_SUCEDIDAS",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-site" style={{ background: "white" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                style={{ maxHeight: "400px" }}
                src="/site/blog/blog_5_habitos_de_pessoas_bem_sucedidas.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                5 Hábitos de pessoas bem-sucedidas
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 22, fontStyle: "italic" }}>
                Alcançar o sucesso financeiro e profissional é um objetivo
                comum, mas que exige disciplina e estratégias bem definidas. As
                pessoas bem-sucedidas compartilham uma série de hábitos que as
                ajudam a se destacar.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 22 }}>
                Separamos cinco hábitos essenciais que podem impulsionar seu
                desenvolvimento e crescimento financeiro e profissional.
                Confira:
              </Typography>
            </Grid>

            <Grid item container spacing={2} className="sessao">
              <ol className="list-habitos">
                <li>
                  <strong>Educação Financeira</strong> <br /> A educação
                  financeira é fundamental para o sucesso a longo prazo. Pessoas
                  bem-sucedidas dedicam tempo para aprender sobre finanças e
                  gerenciamento de dinheiro. Elas compreendem a importância de
                  criar um orçamento, economizar, investir sabiamente e evitar
                  dívidas desnecessárias. Ao dominar esses conceitos, elas
                  conseguem fazer escolhas financeiras inteligentes que
                  impulsionam seu patrimônio e garantem estabilidade econômica.
                </li>
                <li>
                  <strong>Planejamento e Definição de Metas</strong> <br />{" "}
                  Pessoas bem-sucedidas estabelecem objetivos claros e
                  mensuráveis, tanto a curto quanto a longo prazo. Elas criam
                  planos detalhados para atingir essas metas, dividindo-as em
                  etapas menores e mais gerenciáveis. Essa abordagem permite
                  monitorar o progresso e ajustar estratégias conforme
                  necessário, mantendo-se sempre no caminho certo.
                </li>
                <li>
                  <strong>Networking</strong> <br /> Pessoas que buscam o
                  crescimento profissional devem manter relacionamentos sólidos
                  no mercado. Elas participam de eventos, conferências e grupos
                  profissionais para expandir sua rede de contatos. Esses
                  relacionamentos muitas vezes resultam em oportunidades de
                  negócio, parcerias e até mesmo novas ideias que podem levar ao
                  crescimento profissional e financeiro.
                </li>
                <li>
                  <strong>Desenvolvimento Contínuo</strong> <br /> O mundo está
                  em constante evolução, e as pessoas bem-sucedidas sabem que
                  precisam acompanhar as mudanças. Elas investem em seu
                  desenvolvimento contínuo, seja por meio de cursos, leitura de
                  livros ou mentorias. Estar atualizado com as tendências e
                  inovações em sua área de atuação permite que essas pessoas se
                  mantenham competitivas e relevantes.
                </li>
                <li>
                  <strong>Disciplina e Produtividade</strong> <br /> A
                  disciplina é um traço marcante nas pessoas bem-sucedidas. Elas
                  mantêm uma rotina bem organizada e produtiva, priorizando
                  tarefas importantes e evitando procrastinação. A combinação de
                  disciplina e organização resulta em maior eficiência e melhor
                  desempenho no trabalho.
                </li>
              </ol>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22 }}>
                  Adotar esses cinco hábitos pode transformar sua trajetória
                  profissional e financeira. A educação financeira, o
                  planejamento estratégico, o networking, o desenvolvimento
                  contínuo e a disciplina são pilares que sustentam o sucesso.
                  Incorporar essas práticas em sua rotina diária ajudará a
                  construir um futuro próspero e a alcançar seus objetivos de
                  maneira mais eficiente. Comece hoje mesmo a implementar esses
                  hábitos!
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22 }}>
                  Com a solução da <span className="quita">Quita Boletos</span>,
                  você pode dar o primeiro passo para organizar a sua vida
                  financeira. Realize uma simulação e comece a mudança hoje
                  mesmo!
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape />
    </>
  );
}
