import { Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { registerMetadata } from "@/services/metadataEvent";
import { direcionaParaApp } from "@/services/utils";
import "./index.scss";

export default function CarregamentoSimulação() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "LANDING_PAGE_LOADING_SIMULATOR",
      url: window.location.href,
    };
    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
    setTimeout(() => {
      direcionaParaApp("/");
    }, 2000);
  }, [searchParams]);

  return (
    <Container
      sx={{ height: "90vh" }}
      id="carregamento-simulacao"
      component="section"
      className="sessao-site">
      <Helmet>
        <title>Carregando Simulação</title>
        <link rel="canonical" href="https://quitaboletos.com.br" />
        <meta
          name="description"
          content="Simule o parcelamento do seu PIX em até 12x no cartão de crédito."
        />
      </Helmet>

      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Grid sx={{ px: { xs: 2 }, width: { xs: "100%", md: "40%" } }}>
            <Typography variant="h1" sx={{ textAlign: "center" }}>
              Você está sendo direcionado para simulação:
            </Typography>
          </Grid>
          <div className="loader">
            <div className="item-loader"></div>
            <div className="item-loader"></div>
            <div className="item-loader"></div>
            <div className="item-loader"></div>
            <div className="item-loader"></div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
