import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import blogPosts from "../blogPosts";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";

import "./index.scss";

export default function CardPosts({ justThree, ...props }) {
  const navigation = useNavigate();
  const [posts, setPosts] = useState([]);
  const [isThreePosts, setIsThreePosts] = useState(false);
  const initialValues = blogPosts;
  useEffect(() => {
    if (isThreePosts) {
      setIsThreePosts(false);
      const lastPosts = initialValues.slice(-3);
      setPosts(lastPosts.reverse());
    } else if (!isThreePosts && posts.length === 0) {
      setPosts(initialValues.reverse());
    }
  }, [posts, isThreePosts, initialValues]);

  useEffect(() => {
    setIsThreePosts(justThree);
  }, [justThree]);

  return (
    <>
      {posts.map((post) => {
        return (
          <Card key={post.url} {...props}>
            <CardMedia
              className="card-content"
              onClick={() => {
                navigation(justThree ? `/blog/${post.url}` : `${post.url}`);
              }}
              component="img"
              image={post.image}
              title={post.titleImg}
              sx={{
                height: justThree ? 150 : "auto",
              }}
            />

            <CardContent
              className="card-content"
              onClick={() =>
                navigation(justThree ? `/blog/${post.url}` : `${post.url}`)
              }
            >
              <Typography variant="h4">{post.title}</Typography>
              <Typography variant="body2">{post.description}</Typography>
            </CardContent>
            {!justThree && (
              <CardActions sx={{ justifyContent: "end" }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    navigation(justThree ? `/blog/${post.url}` : `${post.url}`)
                  }
                >
                  Continuar lendo
                </Button>
              </CardActions>
            )}
          </Card>
        );
      })}
    </>
  );
}
