// material-ui
import { createTheme } from "@mui/material/styles";

const Palette = (mode) => {
  return createTheme({
    palette: {
      primary: {
        light: "#179b46",
        main: "#179b46",
        dark: "#179b46",
        contrastText: "#ffffff",
      },
      black: {
        light: "#2E302E",
        main: "#2E302E",
        dark: "#2E302E",
        contrastText: "#2E302E",
      },
    },
  });
};

export default Palette;
