import { Container, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import Rodape from "@/pages/site/rodape";
import SeloReclameAqui from "@/components/SeloReclameAqui";
import BotaoFaleConosco from "@/components/BotaoFaleConosco";
import "./index.scss";

export default function PixParcelado() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_LANDING_PAGE_PIX_PARCELADO",
      url: window.location.href,
    };
    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container id="pix-parcelado" component="section" className="sessao-site">
        <Helmet>
          <title>Pix Parcelado</title>
          <link rel="canonical" href="https://quitaboletos.com.br" />
          <meta
            name="description"
            content="Simule o parcelamento do seu PIX em até 12x no cartão de crédito."
          />
        </Helmet>

        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid sx={{ pt: 3, px: 3, mb: { xs: 2, sm: 0 } }}>
              <Typography
                variant="h1"
                sx={{ fontSize: { xs: "3.5rem", md: "4rem" }, mb: 2 }}>
                Está sem dinheiro?
              </Typography>

              <Typography variant="h2" sx={{ fontSize: "4rem" }}>
                Parcele <span className="quita">PIX</span> no cartão de{" "}
                <span className="quita">crédito</span>
              </Typography>

              <Typography sx={{ fontSize: "2rem", mb: 3 }}>
                Divida em até 12x no cartão!
              </Typography>
            </Grid>
            <Grid>
              <BotaoSimular
                sx={{ fontSize: "2rem", width: { xs: "90%", sm: "50%" } }}>
                SIMULAR
              </BotaoSimular>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "end",
              justifyContent: "center",
            }}>
            <img
              className="banner-pix-parcelado"
              src="/landing-pages/pix-parcelado.png"
              alt="Pix Parcelado"
              loading="lazy"
            />
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site">
        <Grid container sx={{ px: { xs: 0, md: 3 } }}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ backgroundColor: "#fff", p: { xs: 4, md: 8 } }}>
            <Typography sx={{ fontSize: "3rem", fontWeight: "bold" }}>
              +250 mil
            </Typography>
            <Typography sx={{ fontSize: "2rem" }} className="quita">
              clientes atendidos
            </Typography>

            <Grid
              sx={{
                maxWidth: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <img
                loading="lazy"
                width={{ xs: "100%", md: "80%" }}
                height={"100%"}
                src="/landing-pages/clientes.png"
                alt="Avaliação dos clientes"
              />
            </Grid>

            <Typography sx={{ fontSize: "2rem", mt: 2 }}>
              8 em cada 10{" "}
              <span className="quita">
                clientes ​avaliam nossa solução e ​atendimento com
              </span>{" "}
              nota 10.
            </Typography>

            <Grid
              sx={{ display: "flex", justifyContent: "center", mt: "2rem" }}>
              <SeloReclameAqui {...{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ p: { xs: 0, sm: "2.5rem" } }}>
            <Grid
              sx={{
                pt: 5,
                px: 3,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
              }}>
              <Typography
                className="quita"
                sx={{
                  width: "100%",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                  borderRadius: "2.5rem",
                  backgroundColor: "#fff",
                  p: 1,
                }}>
                Fácil, seguro e 100% online!
              </Typography>
            </Grid>
            <Grid sx={{ p: 3 }}>
              <Typography
                variant="p"
                sx={{
                  lineHeight: "normal",
                  fontSize: { xs: "2rem", sm: "3rem" },
                  fontWeight: "bold",
                  textAlign: "start",
                }}>
                Nós efetuamos o pagamento PIX àvista, enquanto você parcela em
                até 12 vezes no cartão.
              </Typography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}>
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "2rem", sm: "3rem" },
                  mb: 2,
                  pr: 2,
                }}
                className="quita">
                + flexibilidade
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site">
        <Grid container>
          <Grid item xs={12} lg={6} sx={{ pt: 3, px: 3 }}>
            <Typography
              sx={{
                fontSize: { xs: "2.5rem", lg: "4rem" },
                textAlign: "justify",
                lineHeight: "normal",
              }}>
              Simples e <br /> <span className="quita">sem burocracia</span>
            </Typography>

            <ol className="passos-simulacao">
              <li>
                <strong>Simule seu PIX parcelado: </strong>
                Clique no botão "Simular".
              </li>
              <li>
                <strong>Insira seus dados e os do ​parcelamento: </strong>
                Preencha as ​informações solicitadas.
              </li>
              <li>
                <strong>Escolha o parcelamento: </strong>
                Selecione a opção que melhor ​se encaixa no seu orçamento.
              </li>
              <li>
                <strong>Confirme a operação e os ​dados de pagamento: </strong>
                Verifique ​todos os dados e confirme o ​pagamento.
              </li>
            </ol>

            <Typography sx={{ fontSize: "1.1rem", fontStyle: "italic" }}>
              *Para liberar o pagamento imediatamente, todos os dados precisam
              estar corretos e a simulação deve ser realizada no nome do titular
              do cartão.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              justifyContent: "center",
              backgroundColor: "#2E302E",
            }}>
            <Grid
              sx={{
                maxWidth: "100%",
                maxHeight: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <img
                loading="lazy"
                width={"70%"}
                height={"100%"}
                src="/landing-pages/seguro.png"
                alt="desbloquear saldo na conta?"
              />
            </Grid>
            <Grid sx={{ px: 3, textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { xs: "2.5rem", sm: "3.5rem" },
                  color: "#fff",
                  fontWeight: "bold",
                }}>
                <span className="quita">Desbloqueie</span> sua liberdade
                fincanceira!
              </Typography>

              <BotaoSimular
                variant="outlined"
                size="large"
                sx={{ fontSize: "2rem", width: "50%" }}>
                SIMULAR
              </BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container component="section" className="sessao-site" sx={{ mb: 3 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ display: { xs: "none", lg: "flex" } }}>
            <Grid sx={{ maxWidth: "100%", maxHeight: "100%" }}>
              <img
                loading="lazy"
                width={"100%"}
                height={"100%"}
                src="/landing-pages/banner-secao-seguranca.png"
                alt="etapas de segurança no site"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ p: { xs: "1.5rem", sm: "3.5rem" } }}>
            <Typography
              sx={{
                lineHeight: "normal",
                fontWeight: "bold",
                fontSize: { xs: "2rem", md: "3.5rem" },
              }}>
              Nosso sistema possui múltiplas etapas de{" "}
              <span className="quita">segurança</span>
            </Typography>
            <Typography
              sx={{
                mt: 0.9,
                fontSize: { xs: "1.5rem", md: "2.5rem" },
              }}>
              Para você que busca <strong>flexibilidade</strong> e{" "}
              <strong>segurança</strong> no controle dos seus pagamentos!
            </Typography>
            <Typography
              sx={{
                mt: 0.9,
                fontSize: { xs: "1.5rem", md: "2.5rem" },
              }}>
              Realizar o parcelamento de PIX no cartão de crédito nunca foi tão
              simples e ágil.
            </Typography>

            <Grid sx={{ display: "flex", justifyContent: "center" }}>
              <BotaoSimular
                size="large"
                sx={{ fontSize: "2rem", width: "50%" }}>
                SIMULAR
              </BotaoSimular>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container component="section" className="sessao-site">
        <Grid container sx={{ mb: 3 }}>
          <Grid item xs={12} lg={6}>
            <Grid sx={{ pt: 3, px: 3, mb: { xs: 2, sm: 0 } }}>
              <Typography sx={{ fontSize: "3rem", fontWeight: "bold" }}>
                Ficou alguma dúvida?
              </Typography>
              <Typography sx={{ fontSize: "2rem" }}>
                Chame nosso suporte que vamos te ajudar.
              </Typography>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-start" },
                }}>
                <BotaoFaleConosco
                  sx={{
                    mt: 2,
                    width: { xs: "90%", sm: "50%" },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
}
