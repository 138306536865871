import { Container, Grid, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import BotaoSimular from "@/components/BotaoSimular";
import Rodape from "@/pages/site/rodape";

export default function ComoManterSeusPagamentosEmDia() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction: "VIEW_BLOG_COMO_MANTER_SEUS_PAGAMENTOS_EM_DIA",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-site" style={{ background: "white" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}>
          <Grid container spacing={3} maxWidth="sm" sx={{ py: 3 }}>
            <Grid
              className="logo-blog"
              xs={12}
              sx={{
                display: { xs: "none", sm: "flex" },
                justifyContent: "center",
              }}>
              <img
                style={{ maxHeight: "400px" }}
                src="/site/blog/blog_organizacao_financeira_como_manter_seus_pagamentos_em_dia.png"
                alt="logo-blog"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h1"
                sx={{ mt: 2, p: 1, textAlign: "center" }}>
                Organização financeira: como manter seus pagamentos em dia
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 22 }}>
                Em um mundo onde a gestão financeira pode ser um verdadeiro
                desafio, encontrar soluções que realmente facilitam a vida é
                essencial. Se você busca uma forma prática, flexível e
                descomplicada de organizar suas finanças, a{" "}
                <span className="quita">Quita</span> é a resposta para suas
                necessidades. Com a <span className="quita">Quita</span>, você
                conquista o pódio da organização financeira e muito mais!
              </Typography>
            </Grid>

            <Grid container spacing={2} className="sessao">
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                  O que é a <span className="quita">Quita Boletos</span>?
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  A <span className="quita">Quita</span> é uma plataforma
                  inovadora que transforma a maneira como você lida com seus
                  pagamentos. Oferecemos uma solução 100% online e sem
                  burocracia para você parcelar PIX ou boletos no cartão de
                  crédito, com total flexibilidade. Se você já se viu em apuros
                  por não conseguir gerenciar suas contas de maneira eficiente,
                  a <span className="quita">Quita</span> é a ferramenta que vai
                  colocar sua vida financeira nos trilhos.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                  Flexibilidade total:
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Com a <span className="quita">Quita</span>, você pode escolher
                  o momento ideal para pagar suas contas. Quer parcelar um
                  boleto ou um PIX? Faça isso quando for mais conveniente para
                  você, sem pressão. A flexibilidade oferecida permite que você
                  ajuste os pagamentos conforme suas necessidades financeiras,
                  evitando sobrecargas e facilitando a gestão do seu orçamento.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                  100% online e sem burocracia:
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Esqueça a papelada e os processos complicados. A{" "}
                  <span className="quita">Quita</span> oferece uma experiência
                  totalmente digital, simples e intuitiva. Em poucos cliques,
                  você pode organizar suas contas e definir o melhor plano de
                  pagamento para você. A nossa plataforma elimina a burocracia,
                  permitindo que você concentre seu tempo no que realmente
                  importa.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                  Ganhe mais tempo e tranquilidade:
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Ao utilizar a <span className="quita">Quita</span>, você ganha
                  mais tempo para planejar e pagar suas contas. Isso significa
                  menos estresse e mais liberdade financeira. Com a nossa
                  solução, você não precisa se preocupar com prazos apertados ou
                  com a pressão de pagamentos em um curto espaço de tempo.
                  Aproveite a tranquilidade de ter mais controle sobre suas
                  finanças!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                  Por que escolher a{" "}
                  <span className="quita">Quita Boletos</span>?
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Nossa plataforma é projetada para ser fácil de usar. Com
                  alguns cliques, você pode gerenciar seus pagamentos sem
                  dificuldades. Ajuste o número de parcelas de acordo com suas
                  preferências, adaptando a solução às suas necessidades
                  específicas.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22, fontWeight: "bold" }}>
                  Suporte dedicado:
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Nossa equipe está pronta para ajudar com qualquer dúvida ou
                  necessidade que você possa ter, garantindo que sua experiência
                  com a <span className="quita">Quita</span> seja sempre
                  positiva.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ fontSize: 22 }}>
                  Organizar suas finanças nunca foi tão fácil e acessível. Com a{" "}
                  <span className="quita">Quita</span>, você tem a oportunidade
                  de alcançar o pódio da organização financeira. Experimente a
                  liberdade de um sistema flexível e eficiente e descubra como é
                  simples manter suas contas em dia sem complicações.
                </Typography>
                <Typography sx={{ fontSize: 22, my: 2 }}>
                  Não deixe que a gestão financeira seja um obstáculo para a sua
                  tranquilidade. Venha para a{" "}
                  <span className="quita">Quita</span> e veja como podemos
                  ajudar você a conquistar a vitória nas suas finanças!
                </Typography>
                <Typography sx={{ fontSize: 22 }}>
                  Clique no botão abaixo e comece a sua jornada para uma vida
                  financeira mais organizada e sem estresse.
                </Typography>
              </Grid>

              <Grid item xs={12} align="center">
                <BotaoSimular />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape />
    </>
  );
}
