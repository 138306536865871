import { post } from "./api";

export async function registerMetadata(data, idMetadata) {
  try {
    const result = await post(`public/register-metadata`, data);
    if (idMetadata) {
      sessionStorage.setItem(idMetadata, result._id);
    }
  } catch (e) {
    console.log(e);
  }
}
