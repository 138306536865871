import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { registerMetadata } from "@/services/metadataEvent";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rodape from "@/pages/site/rodape";
import BotaoSimular from "@/components/BotaoSimular";

export default function ParcelamentoDeContasTudoQuePrecisaSaber() {
  const [searchParams] = useSearchParams();
  searchParams.get("__firebase_request_key");

  useEffect(() => {
    const metaData = {};
    metaData.utmSource = searchParams.get("utm_source");
    metaData.utmMedium = searchParams.get("utm_medium");
    metaData.utmCampaign = searchParams.get("utm_campaign");
    metaData.utmId = searchParams.get("utm_id");
    metaData.utmTerm = searchParams.get("utm_term");
    const metadataRegister = {
      ...metaData,
      isMobile: isMobile,
      userAction:
        "VIEW_BLOG_PARCELAMENTO_DE_CONTAS_TUDO_QUE_VOCE_PRECISA_SABER",
      url: window.location.href,
    };

    registerMetadata(metadataRegister, "METADATA_VIEW_SITE_QUITABOLETOS");
  }, [searchParams]);

  return (
    <>
      <Container className="sessao-site " style={{ background: "white" }}>
        <Grid container justifyContent="center">
          <Grid
            xs={12}
            sx={{ p: 2, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={3} maxWidth="sm" sx={{ pt: 3, pb: 3 }}>
              <Grid
                className="logo-blog"
                xs={12}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyContent: "center",
                }}>
                <img
                  style={{ maxHeight: "400px" }}
                  src="/site/blog/blog_parcelamento_de_contas_tudo_que_precisa_saber.png"
                  alt="logo-blog"
                />
              </Grid>

              <Grid
                item
                xs={12}
                className="titulo"
                sx={{ paddingTop: { xs: 4, sm: 0 } }}>
                <Typography variant="h1" sx={{ textAlign: "center", mt: 2 }}>
                  Parcelamento de contas: tudo que voce precisa saber
                </Typography>
              </Grid>

              <Grid container spacing={1} className="sessao">
                <Grid item xs={12}>
                  <Typography variant="h3" className="titulo">
                    O parcelamento de contas tornou-se uma ferramenta essencial
                    para muitos brasileiros. Seja para equilibrar o orçamento,
                    adquirir produtos de maior valor ou simplesmente facilitar a
                    administração das finanças pessoais, essa modalidade de
                    pagamento oferece flexibilidade e conveniência.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24 }}
                    className="descricao"
                    component={"article"}>
                    Segundo a <strong>Abecs</strong> (Associação Brasileira das
                    Empresas de Cartões de Crédito e Serviços), o pagamento
                    parcelado é usado por{" "}
                    <strong>
                      62% dos compradores que fazem compras com cartão de
                      crédito a cada mês
                    </strong>
                    . A popularidade deste método de pagamento se justifica
                    porque a possibilidade de parcelar uma compra aumenta o
                    poder aquisitivo dos consumidores das classes sociais mais
                    baixas do país. Graças ao parcelamento, uma grande fatia da
                    população obtém acesso mais fácil a produtos com preços mais
                    elevados.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: 24, fontStyle: "italic" }}
                    className="descricao"
                    component={"article"}>
                    Neste artigo, vamos explorar tudo o que você precisa saber
                    sobre o parcelamento de contas, desde suas vantagens até as
                    melhores práticas para utilizá-lo de forma eficiente e
                    segura. Prepare-se para transformar a maneira como você
                    gerencia suas despesas!
                  </Typography>
                </Grid>

                <Grid item container spacing={2} className="sessao">
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      O que é e como funciona o parcelamento de contas:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      component={"article"}
                      sx={{ fontSize: 20, pb: 1 }}>
                      O parcelamento de contas é uma modalidade de pagamento que
                      permite ao consumidor dividir o valor total de uma conta
                      ou compra em múltiplas parcelas, pagas ao longo de um
                      período de tempo. Esta prática é amplamente utilizada no
                      Brasil, especialmente para compras de maior valor ou para
                      facilitar a gestão de despesas mensais.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      Parcelar contas no cartão de crédito vale a pena?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      O parcelamento de contas ajuda na distribuição das
                      despesas ao longo de vários meses, evitando um impacto
                      financeiro significativo em um único mês. Isso é
                      particularmente útil para despesas inesperadas ou
                      sazonais.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      Ao optar pelo parcelamento no cartão de crédito, os
                      consumidores podem manter uma reserva de dinheiro para
                      emergências ou outras necessidades, em vez de comprometer
                      todo o seu capital em uma única transação.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      Permite um melhor planejamento e controle financeiro,
                      sendo especialmente útil em momentos de aperto, pois as
                      parcelas fixas facilitam a previsão de despesas futuras e
                      ajudam na organização do orçamento mensal.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      O parcelamento de contas é uma ferramenta útil que, quando
                      utilizada de forma consciente e planejada, pode
                      proporcionar maior flexibilidade financeira e melhorar a
                      qualidade de vida dos consumidores.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h2">
                      Como a <span className="quita">Quita Boletos</span> pode
                      ajudar no parcelamento de contas?{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      Com o parcelamento da{" "}
                      <span className="quita">Quita Boletos</span>, você pode
                      parcelar seus boletos ou PIX de forma rápida, segura e sem
                      complicações. Podendo utilizar mais de um cartão de
                      crédito para parcelar suas contas em até 12 vezes.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      O parcelamento de contas é uma solução prática e eficiente
                      para quem busca maior flexibilidade financeira e melhor
                      gestão do orçamento. Com a possibilidade de dividir
                      pagamentos em parcelas fixas, essa modalidade permite que
                      consumidores adquiram produtos e serviços de maior valor,
                      planejem suas despesas futuras e mantenham uma reserva
                      financeira para emergências.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ fontSize: 20, pb: 1 }}
                      component={"article"}>
                      No entanto, é essencial utilizar o parcelamento de forma
                      consciente, evitando o endividamento excessivo e
                      garantindo que as parcelas se ajustem ao seu orçamento
                      mensal. Com um planejamento adequado, o parcelamento de
                      contas pode ser uma ferramenta valiosa para alcançar
                      estabilidade financeira e tranquilidade nas finanças
                      pessoais.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} align="center">
                    <BotaoSimular></BotaoSimular>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
}
