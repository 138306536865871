import SiteLayout from "@/layout/SiteLayout";
import Parceiros from "@/pages/site/Parceiros";
import Inicio from "@/pages/site/Inicio";
import Ajuda from "@/pages/site/Ajuda";
import TermosUso from "@/pages/site/TermosUso";

const SiteRoutes = {
  path: "/",
  element: <SiteLayout />,
  children: [
    {
      path: "/",
      element: <Inicio />,
    },
    {
      path: "/parceiros",
      element: <Parceiros />,
    },
    {
      path: "/termos-de-uso",
      element: <TermosUso />,
    },
    {
      path: "/ajuda",
      element: <Ajuda />,
    },
    {
      path: "/como-funciona",
      element: <Ajuda />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default SiteRoutes;
