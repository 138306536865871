import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Paper } from "@mui/material";
import { abreSimulador } from "@/services/utils";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Rodape from "../rodape";
import GroupsIcon from "@mui/icons-material/Groups";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import CardPosts from "../Blog/CardPosts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CookieBanner from "@/components/CookieBanner";
import "./index.scss";
import BotaoPrincipal from "../../../components/BotaoPrincipal";

const Inicio = () => {
  const navigation = useNavigate();
  return (
    <>
      <Container id="inicio" component="main" className="sessao-site sessao-1">
        <Helmet>
          <title>Quita Boletos</title>
          <link rel="canonical" href="https://quitaboletos.com.br" />
          <meta
            name="description"
            content="Simule o parcelamento de seu boleto ou PIX em até 12x no cartão de crédito."
          />
        </Helmet>

        <CookieBanner />

        <Grid container sx={{ p: 3, m: "auto" }} maxWidth="lg">
          <Grid item xs={12} sm={6} sx={{ p: 1 }}>
            <Typography
              variant="h1"
              className="texto-parcele-em-x-vezes"
              sx={{ pt: { xs: 0, md: 5 } }}>
              PARCELE PIX OU BOLETOS EM ATÉ 12X NO CARTÃO
            </Typography>
            <Typography className="texto-secundario" sx={{ pt: 2 }}>
              Solução prática, eficiente e segura.
            </Typography>
            <Button
              variant="contained"
              className="botao botao-simular-principal"
              size="large"
              sx={{ mt: 2, pr: 2, pl: 2, display: "block" }}
              onClick={() => {
                abreSimulador();
              }}>
              SIMULAR AGORA
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            sx={{
              display: { xs: "none", sm: "block" },
            }}>
            <img className="banner" src="/site/banner.png" alt=""></img>
          </Grid>
        </Grid>
      </Container>

      <Container className="sessao-site sessao-2">
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography variant="h3" className="titulo">
              Já sabe como funciona?
            </Typography>
            <Typography variant="h3" className="titulo">
              É muuuuito fácil!
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{ p: 2 }}
          justifyContent="center"
          className="informacao-dados"
          onClick={() => {
            abreSimulador();
          }}>
          <Grid item xs={12} align="center">
            <Typography variant="h3" className="subtitulo-como-funciona">
              Parcelamos seus débitos em até 12x no cartão de crédito.
            </Typography>
            <Typography>Para contratar siga os passos abaixo:</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">1</span>Simule
              </Typography>
              <Typography className="descricao">
                Clique em simular, informe seus dados e o valor da simulação.
              </Typography>
              <Typography className="descricao">
                Depois selecione qual a forma de pagamento que deseja realizar
                com seu cartão de crédito.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">2</span>Informe os itens para
                pagamento
              </Typography>
              <Typography className="descricao">
                Selecione quais débitos que deseja realizar o parcelamento e
                informe os dados correspondentes.
              </Typography>
              <Typography className="descricao">
                Após informar os dados avance para o pagamento.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">3</span>Informe seus dados
              </Typography>
              <Typography className="descricao">
                Para prosseguir a contratação precisamos de alguns dados
                pessoais como nome completo, CPF, endereço, etc.
              </Typography>
              <Typography className="descricao">
                Após informar os dados avance para o pagamento.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="item-como-funciona">
              <Typography className="subtitulo">
                <span className="titulo-numero">4</span>Pagamento
              </Typography>
              <Typography className="descricao">
                Pronto, agora é só informar os dados do seu cartão de crédito e
                confirmar a contratação!
              </Typography>
              <Typography className="descricao">Fácil né?!</Typography>
            </div>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              className="botao botao-simular"
              size="large"
              onClick={() => {
                abreSimulador();
              }}>
              CLIQUE AQUI PARA COMEÇAR
            </Button>
          </Grid>
        </Grid>{" "}
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid align={"center"} item xs={12} sm={12}>
            <div className="logo-brand">
              <img src="/site/logo-mastercard.webp" alt="bandeira-mastercard" />
              <img src="/site/logo-visa.webp" alt="bandeira-visa" />
              <img src="/site/logo-elo.webp" alt="bandeira-elo" />
              <img src="/site/logo-cabal.webp" alt="bandeira-cabal" />
              <img
                src="/site/logo-american-express.webp"
                alt="bandeira-american-express"
              />
              <img src="/site/logo-hipercard.webp" alt="bandeira-hipercard" />
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container className="sessao-site sessao-conte-conosco">
        <Grid
          container
          spacing={3}
          sx={{ m: { md: "auto" } }}
          maxWidth="md"
          justifyContent="center">
          <Grid
            item
            xs={12}
            sm={5}
            sx={{
              textAlign: { xs: "left", sm: "right" },
            }}>
            <Typography className="titulo">
              Conte com a gente na <br />
              hora do aperto
            </Typography>

            <Typography className="subtitulo">
              Mantenha seu nome limpo
            </Typography>

            <Typography className="descricao">
              Sabe aquela dívida que impede você de deixar tudo em dia e ficar
              com o nome limpo? Aqui, fica fácil realizar o pagamento. Com o
              parcelamento em até 12x, em quantos cartões de crédito quiser.
            </Typography>

            <Typography className="subtitulo">
              Organize suas finanças
            </Typography>

            <Typography className="descricao">
              Alguns meses são realmente mais difíceis que outros. O orçamento
              aperta e, sem a gente ter previsto, falta grana para quitar todos
              os compromissos. Aqui, fica fácil rever o planejamento e
              reorganizar as parcelas.
            </Typography>

            <Typography className="subtitulo">
              Tire os planos do papel
            </Typography>

            <Typography className="descricao">
              Tem uma boa ideia e precisa de grana para executar? Não consegue
              inserir o valor de uma vez só no seu orçamento? Aqui, fica fácil
              parcelar e finalmente executar o tão esperado objetivo.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            sx={{
              alignItems: "center",
              display: { xs: "none", sm: "flex" },
            }}>
            <Typography className="imagem">
              <img src="/site/conte-conosco.png" alt=""></img>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-qualidade-testada">
        <Grid container justifyContent="center">
          <Grid item xs={12} align="center">
            <Typography variant="h3" className="titulo">
              Qualidade testada e aprovada
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography>Confira alguns locais que estamos presentes</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ m: { sm: "auto" } }}
          maxWidth="sm"
          className="item-programas"
          justifyContent="center">
          <Grid item xs={12} md={3} sx={{ display: "flex" }}>
            <Typography className="imagem">
              <img src="/site/miralabs.png" alt=""></img>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <div className="textos">
              <Typography className="subtitulo">Mira Labs</Typography>

              <Typography className="descricao">
                <b>Pré-aceleradora de negócios</b>
              </Typography>

              <Typography className="descricao">
                O Programa seleciona startups do interior, que passam por um
                programa de pré-aceleração conectado às mais recentes
                metodologias de negócios tecnológicos e de alto impacto no
                mundo. Através de eventos de networking, mentorias e workshops,
                as empresas recebem apoio para potencializar seus negócios.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: 2, display: "flex" }}>
            <Typography className="imagem">
              <img src="/site/capital-empreendedor.png" alt=""></img>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ mt: 2 }}>
            <div className="textos">
              <Typography className="subtitulo">
                Capital Empreendedor
              </Typography>

              <Typography className="descricao">
                <b>SEBRAE</b>
              </Typography>

              <Typography className="descricao">
                O Programa Capital Empreendedor, iniciativa do Sebrae Nacional,
                visando preparar e educar empreendedores e sócios fundadores de
                startups inovadoras no âmbito dos investimentos de risco — uma
                das formas de captar recursos para realizar planos e projetos de
                longo prazo do seu negócio.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mt: 2, display: "flex" }}>
            <Typography className="imagem">
              <img src="/site/caldeira.png" alt=""></img>
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} sx={{ mt: 2 }}>
            <div className="textos">
              <Typography className="subtitulo">Instituto Caldeira</Typography>

              <Typography className="descricao">
                <b>Programa Ebulição</b>
              </Typography>

              <Typography className="descricao">
                Maior Hub de Inovação do Rio Grande do Sul (e um dos maiores do
                Brasil), foi fundado por 42 grandes empresas do estado. Atua com
                o propósito de impulsionar transformações positivas e concretas
                através da inovação.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-3">
        <Grid container justifyContent="center" sx={{ pb: 3 }}>
          <Grid item xs={12} md={8} align="center">
            <Typography variant="h3" className="titulo">
              Nosso propósito é ajudar você a manter os pagamentos em dia!
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          className="informacao-dados">
          <Grid
            item
            justifyContent="center"
            xs={12}
            className="imagens"
            onClick={() => {
              abreSimulador();
            }}
            style={{ marginBottom: "5rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                "& > :not(style)": {
                  width: 400,
                  height: 250,
                },
              }}>
              <Paper elevation={3} className="paper-container" sx={{ mb: 2 }}>
                <div
                  style={{
                    marginBottom: "1rem",
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}>
                  <div className="proposito-text">
                    Mais de <span style={{ color: "#2E302E" }}>250 mil</span>{" "}
                    clientes atendidos!
                  </div>
                  <div>
                    <GroupsIcon
                      style={{
                        display: "flex",
                        fontSize: "5rem",
                      }}></GroupsIcon>
                  </div>
                </div>
              </Paper>
              <Paper elevation={3} className="paper-container2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}>
                  <div className="proposito-text2">
                    Mais de <span style={{ color: "#1DD15D" }}>35 milhões</span>{" "}
                    de débitos parcelados!
                  </div>
                  <div>
                    <PaymentsOutlinedIcon
                      style={{
                        display: "flex",
                        fontSize: "5rem",
                        color: "#1DD15D",
                      }}></PaymentsOutlinedIcon>
                  </div>
                </div>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container
        id="duvidas-frequentes"
        className="sessao-site sessao-faq"
        component="section">
        <Typography variant="h3" className="titulo">
          Dúvidas frequentes
        </Typography>

        <Grid container maxWidth="sm">
          <Grid className="item-faq" xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">Quita Boletos é confiável?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Sim, nosso sistema utiliza diversas etapas de segurança na
                  operação como <b>biometria</b> e <b>antifraude</b>.
                </Typography>
                <Typography className="descricao">
                  Todas as informações enviadas possuem criptografia de ponta a
                  ponta para garantir a confidencialidade da contratação.
                </Typography>
                <Typography className="descricao">
                  Nosso sistema utiliza diversos mecanismos para garantir a
                  segurança dos seus dados com qualidade testada e aprovada.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="item-faq" xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Por que preciso informar meus dados pessoais na contratação?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Precisamos dos seus dados para garantir a total segurança da
                  operação tanto para a{" "}
                  <span className="quita">Quita Boletos</span> quanto para o
                  contratante.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="item-faq" xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Qual o prazo para meus débitos serem quitados?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Nosso prazo médio é de apenas algumas horas porém por via de
                  regra pode levar até 48 horas úteis após a transação ser
                  autorizada.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="item-faq" xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Como eu simulo o parcelamento do meu débito?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Você pode simular clicando{" "}
                  <Button variant="text" onClick={() => abreSimulador()}>
                    AQUI
                  </Button>{" "}
                  ou em <b>SIMULAR</b>.
                </Typography>
                <Typography className="descricao">
                  Primeiro você deve informar seus dados de contato com o valor
                  da simulação.
                </Typography>
                <Typography className="descricao">
                  Depois você escolhe as condições de parcelamento, informa os
                  dados do boleto e realiza o pagamento.
                </Typography>
                <Typography className="descricao">
                  Pronto, se a transação for autorizada é só aguardar que iremos
                  quitar seus débitos.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="item-faq" xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Existem limites para a contratação?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  O valor máximo para contratação é de R$ 8.000,00.
                </Typography>
                <Typography className="descricao">
                  Se você precisa de um valor maior, você pode realizar mais de
                  uma operação ou entrando em contato conosco.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid className="item-faq" xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="titulo-item-faq"
                id="panel1a-header">
                <Typography variant="h3">
                  Quais tipos de boleto posso parcelar?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="descricao">
                  Você pode parcelar <b>contas de luz</b>, <b>àgua</b>,{" "}
                  <b>telefone</b>, <b>aluguel</b>, <b>condomínio</b>,{" "}
                  <b>prestação do carro ou moto</b>, <b>impostos</b>, <b>etc</b>
                  .
                </Typography>
                <Typography className="descricao">
                  Qualquer boleto pode ser parcelado desde que não seja de
                  depósito para o titular do cartão.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Container>
      <Container className="sessao-site sessao-blog" component="section">
        <Grid
          container
          spacing={3}
          sx={{
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <Grid sx={{ display: "grid", justifyItems: "center" }}>
            <Typography
              variant="h3"
              className="titulo"
              align="center"
              sx={{ my: 2 }}>
              Acompanhe nosso Blog!!
            </Typography>

            <Grid
              item
              xs={12}
              sx={{
                justifyContent: "center",
                display: { xs: "block", sm: "flex" },
                flexWrap: "nowrap",
              }}>
              <CardPosts
                sx={{
                  maxWidth: 290,
                  margin: "1rem 0.5rem",
                  borderRadius: "25px 5px",
                }}
                {...{
                  justThree: true,
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}>
              <BotaoPrincipal
                variant="contained"
                className="btn-blog"
                onClick={() => {
                  navigation("blog");
                }}>
                Conferir mais conteúdos
              </BotaoPrincipal>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Rodape></Rodape>
    </>
  );
};

export default Inicio;
