export default function SeloReclameAqui({ maxWidth }) {
  return (
    <div>
      <img
        src="/site/selo-reclameaqui.png"
        style={{ maxWidth: maxWidth || "110px" }}
        alt=""></img>
    </div>
  );
}
