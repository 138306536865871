import SiteLayout from "@/layout/SiteLayout";
import Inicio from "@/pages/site/Inicio";
import PixParcelado from "@/pages/landing-pages/pix-parcelado";
import CarregamentoSimulação from "../pages/landing-pages/carregamento-simulacao";

const LandingPagesRoutes = {
  path: "/lp",
  element: <SiteLayout />,
  children: [
    {
      path: "/lp",
      element: <Inicio />,
    },
    {
      path: "pix-parcelado",
      element: <PixParcelado />,
    },
    {
      path: "carregando-simulacao",
      element: <CarregamentoSimulação />,
    },
    {
      path: "*",
      element: <Inicio />,
    },
  ],
};

export default LandingPagesRoutes;
